<script lang="ts">
  import { Accordion as AccordionPrimitive } from "bits-ui";
  import { cn } from "@/helpers/tw-utils";

  type $$Props = AccordionPrimitive.TriggerProps;
  type $$Events = AccordionPrimitive.TriggerEvents;

  interface Props {
    class?: $$Props["class"];
    level?: AccordionPrimitive.HeaderProps["level"];
    children?: import('svelte').Snippet;
    [key: string]: any
  }

  let { class: className = undefined, level = 3, children, ...rest }: Props = $props();
  
</script>

<AccordionPrimitive.Header {level} class="flex">
  <AccordionPrimitive.Trigger
    class={cn(
      "flex flex-1 items-center justify-between py-4 font-medium transition-all hover:underline [&[data-state=open]>svg]:rotate-180",
      className
    )}
    {...rest}
    on:click>
    {@render children?.()}

    <svg
      class="h-6 w-6 transition-transform duration-200"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none">
      <mask
        id="mask0_5619_1715"
        style="mask-type:alpha"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24">
        <rect width="24" height="24" rx="2" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_5619_1715)">
        <path
          d="M13.4142 14.5858C12.6332 15.3668 11.3668 15.3668 10.5858 14.5858L8.41421 12.4142C7.15428 11.1543 8.04662 9 9.82843 9H14.1716C15.9534 9 16.8457 11.1543 15.5858 12.4142L13.4142 14.5858Z"
          fill="#FAFAFA" />
      </g>
    </svg>
  </AccordionPrimitive.Trigger>
</AccordionPrimitive.Header>
